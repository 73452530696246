
@font-face {
  font-family: "Pio Thin";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Thin.ttf");
}

.text-thin {
  font-family: "Pio Thin";
}

@font-face {
  font-family: "Pio ThinItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ThinItalic.ttf");
}

.text-thin-italic {
  font-family: "Pio ThinItalic";
}

@font-face {
  font-family: "Pio ExtraLight";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraLight.ttf");
}

.text-extra-light {
  font-family: "Pio ExtraLight";
}

@font-face {
  font-family: "Pio ExtraLightItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraLightItalic.ttf");
}

.text-extra-light-italic {
  font-family: "Pio ExtraLightItalic";
}

@font-face {
  font-family: "Pio Light";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Light.ttf");
}

.text-light {
  font-family: "Pio Light";
}

@font-face {
  font-family: "Pio LightItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-LightItalic.ttf");
}

.text-light-italic {
  font-family: "Pio LightItalic";
}

@font-face {
  font-family: "Pio Regular";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Regular.ttf");
}

.text-regular {
  font-family: "Pio Regular";
}

@font-face {
  font-family: "Pio Italic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Italic.ttf");
}

.text-italic {
  font-family: "Pio Italic";
}

@font-face {
  font-family: "Pio Medium";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Medium.ttf");
}

.text-medium {
  font-family: "Pio Medium";
}

@font-face {
  font-family: "Pio MediumItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-MediumItalic.ttf");
}

.text-medium-italic {
  font-family: "Pio MediumItalic";
}


@font-face {
  font-family: "Pio SemiBold";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-SemiBold.ttf");
}

.text-semi-bold {
  font-family: "Pio SemiBold";
}

@font-face {
  font-family: "Pio SemiBoldItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}

.text-semi-bold-italic {
  font-family: "Pio SemiBoldItalic";
}

@font-face {
  font-family: "Pio Bold";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Bold.ttf");
}

.text-bold {
  font-family: "Pio Bold";
}


@font-face {
  font-family: "Pio BoldItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-BoldItalic.ttf");
}

.text-bold-italic {
  font-family: "Pio BoldItalic";
}


@font-face {
  font-family: "Pio ExtraBold";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraBold.ttf");
}

.text-extra-bold {
  font-family: "Pio ExtraBold";
}

@font-face {
  font-family: "Pio ExtraBoldItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}

.text-extra-bold-italic {
  font-family: "Pio ExtraBoldItalic";
}

@font-face {
  font-family: "Pio Black";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Black.ttf");
}

.text-Black {
  font-family: "Pio Black";
}

@font-face {
  font-family: "Pio BlackItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-BlackItalic.ttf");
}

.text-black-italic {
  font-family: "Pio BlackItalic";
}

.primary-color{
  color: #2D53DA;
}

.page-a4{
  background-color: #FFFFFF;
  width: 8.27in;
  height: 11.69in;
  padding: 1.6cm;
  position: relative;
 }
 .page-a4-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right:  0;
  padding: 1.5rem;
  background-color: white;
} 
.page-footer{
  position: absolute;
   bottom: 0;
   left: 0;
   right:  0;
   padding: 1.5rem;
   background-color: white;
 }

.project:hover .card-hover{
   background-color: #E5E7EB;
}
 
.dir-rtl{
  direction: rtl !important;
}


.form-add-button{
  background-color: #2d53da !important;
  color: #FFFFFF !important;
  border-color:  #2d53da !important;
}
.form-add-button:hover{
  background-color: #27325c !important;
  color: #FFFFFF !important;
  border-color:  #27325c !important;
}

.form-save-button{
  background-color: #FFFFFF !important;
  color: #2d53da !important;
  border-color:  #2d53da !important;
}
.form-save-button:hover{
  background-color: #FFFFFF !important;
  color: #27325c !important;
  border-color:  #27325c !important;
}

.form-cancel-button{
  background-color: #FFFFFF !important;
  color: #ef4444 !important;
  border-color:  #ef4444 !important;
}
.form-cancel-button:hover{
  background-color: #FFFFFF !important;
  color: #b91c1c !important;
  border-color:  #b91c1c !important;
}

.form-back-button{
  background-color: #FFFFFF !important;
  color: #4b4b4b !important;
  border-color:  #4b4b4b !important;
}
.form-back-button:hover{
  background-color: #FFFFFF !important;
  color: #000000 !important;
  border-color:  #000000 !important;
}

.breadcrumb-tab-color {
  color: #8C8C8C !important;
}
.breadcrumb-tab-color:hover {
  color: #2d53da !important;
}

